import React from 'react';
import DrawerComponent from '../Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavbar } from './slices/navbar.slice';
import home_icon from '../../assets/Image 1/DCLogoVector2.svg';
import analysis_icon from '../../assets/Image 1/analytics.svg';
import chat_icon from '../../assets/Image 1/chat-manager.svg';
import chatBotFace_icon from '../../assets/Image 1/chatbot.svg';
import template_icon from '../../assets/Image 1/template.svg';
import share_icon from '../../assets/Image 1/share.svg';
import chatbot_builder from '../../assets/Image 1/chatbot-builder.svg';
import teams_icon from '../../assets/Image 1/campign-manager.svg';
import user_icon from '../../assets/Image 1/user.svg';
import team_icon from '../../assets/Image 1/team.svg';
import users_icon from '../../assets/Image 1/whatsapp.svg';
import channel_icon from '../../assets/Image 1/channel.svg';
import tags_icon from '../../assets/Image 1/contacts.svg';
import star_icon from '../../assets/Image 1/star.png';
import billing_icon from '../../assets/Image 1/billing.svg';
import setting_icon from '../../assets/Image 1/settings.svg';
import logo from '../../assets/dc-new-logo.png';
import { STRINGS } from '../../utils/base';
import '../../App.css';

import { Link, useLocation } from 'react-router-dom';

const navbarData = [
   {
      icon: home_icon,
      value: 'Home',
      isBlank: false,
      route: `${STRINGS.ROUTES.ROOT}?org=${localStorage.getItem('userId')}`,
   },
   {
      icon: analysis_icon,
      value: 'Insights',
      isBlank: true,
      route: `http://localhost:8090/eoceanwab/customerstatistics/index`,
   },
   {
      icon: chat_icon,
      value: 'Chat Manager',
      isBlank: true,
      route: `http://localhost:8090/eoceanwab/chatmanager/index`,
   },
   {
      icon: chatbot_builder,
      value: 'Chatbot builder',
      isBlank: true,
      route: `http://localhost:8090/eoceanwab/chatbotbuilder/index`,
   },
   {
      icon: channel_icon,
      value: 'Channels',
      isBlank: true,
      route: `${process.env.REACT_APP_PORTAL_URl}/eoceanwab/channels/index`,
   },
   {
      icon: channel_icon,
      value: 'Complain',
      isBlank: true,
      route: `${process.env.REACT_APP_PORTAL_URl}/eoceanwab/complain/index`,
   },
   {
      icon: teams_icon,
      value: 'Profile Settings',
      isBlank: true,
      route: ` http://localhost:8090/eoceanwab/Settings/profilesettings`,
   },
   {
      icon: users_icon,
      value: 'Settings',
      isBlank: false,
      route: `${STRINGS.ROUTES.SETTINGS}?org=${localStorage.getItem('userId')}`,
   },

   {
      icon: tags_icon,
      value: "What's New",
      isBlank: true,
      route: `http://localhost:8090/eoceanwab/whatsnew/index`,
   },
];

const NavBar = () => {
   const location = useLocation();
   console.log(location.pathname);
   const navbar = useSelector(({ Reducers }) => Reducers.navbar);
   let { open } = navbar;

   const dispatch = useDispatch();

   const toggleDrawer = () => {
      dispatch(toggleNavbar(false));
   };


   

   // return (

   //     <>

   //     <DrawerComponent open={open} data={navbarData} direction={"left"} toggleDrawer={toggleDrawer}/>
   //     </>

   // )
   return (

      <div className="vertical-layout vertical-menu-modern content-left-sidebar navbar-floating footer-static menu-collapsed" data-open="click" data-menu="vertical-menu-modern" data-col="content-left-sidebar">
      <div className="pace  pace-inactive"><div className="pace-progress" data-progress-text="100%" data-progress="99">
  <div className="pace-progress-inner"></div>
</div>
<div className="pace-activity"></div></div>
      <div id="main-menu" className="main-menu menu-fixed menu-light menu-accordion menu-shadow menu-dark"
         data-scroll-to-active="true">
      <div className="navbar-header menu-collapsed">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto" style={{marginRight: 'auto'}}>

            <a className="navbar-brand m-0 p-0" href="../../../html/ltr/vertical-menu-template/index.html">
              <span style={{ width: '190px' }}>
                <img src={logo} alt="Chatbot" style={{ marginTop: '0px', maxWidth: '100%', width: '50%' }} className="half-logo d-none" />
              </span>
            </a>
            <a className="navbar-brand m-0" href="../../../html/ltr/vertical-menu-template/index.html">
              <span className="brand-logo" style={{ width: '190px' }}>
                <img src={logo} alt="Chatbot" style={{ maxWidth: '40px' }} className="full-logo" />
              </span>
            </a>
          </li>
          {/* <li className="nav-item nav-toggle">
            <a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x d-block d-xl-none text-white toggle-icon font-medium-4">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-disc d-none d-xl-block collapse-toggle-icon primary text-white font-medium-4">
                <circle cx="12" cy="12" r="10" />
                <circle cx="12" cy="12" r="3" />
              </svg>
            </a>
          </li> */}

         <li class="nav-item nav-toggle">
            <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse" style={{display: 'block', background: '#0000'}}>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle d-none d-xl-block collapse-toggle-icon font-medium-4" style={{
                  width: '19px'
               }}>
            <circle className='toggled-menu d-none' cx="12" cy="12" r="3"></circle>
            <circle cx="12" cy="12" r="10"></circle></svg> </a>
         </li>

        </ul>
      </div>
      <div class="shadow-bottom"></div>
       <div class="main-menu-content ps ps--active-y">
          <ul class="navigation navigation-main scroll_navBar" id="main-menu-navigation"
             data-menu="menu-navigation">
                <li id="statsLi" class="nav-item">
                   <a
                   class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/holisticanalysis/index"}> 
                   <i class="fa-solid fa-chart-simple"></i>
                   <span
                      class="menu-item text-truncate" data-i18n="Analytics">Insights</span>
                   </a>
                </li>
                <li id="chatmanagerLi" class="nav-item">
                   <a id="chatAnchor"
                   class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/chatmanager/index?number=&type=&id="}>
                   <i class="fas fa-comments"></i> <span
                      class="menu-title text-truncate" data-i18n="Chat">Chat
                   Manager</span>
                   </a>
                </li>
                <li id="chatbotManagerLi" class="nav-item">
                   <a id="chatAnchor"
                   class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/chatbotmanager/index"}> <i
                      class="fas fa-robot"></i> <span class="menu-title text-truncate"
                      data-i18n="Chat"> Bot Conversations</span>
                   </a>
                </li>
                <li id="templateManagerLi" class="nav-item">
                   <a id="chatAnchor"
                   class="d-flex align-items-center" href={process.env.REACT_APP_PORTAL_URl+"/campaignmessages/index?msgType="}> <i
                      class="far fa-file-alt"></i> <span
                      class="menu-title text-truncate" data-i18n="Chat">Template
                   Manager</span>
                   </a>
                </li>
                <li id="campaignLi" class="nav-item">
                   <a id="chatAnchor"
                   class="d-flex align-items-center" href={process.env.REACT_APP_PORTAL_URl+"/segments/index?segmentName=&segmentType=&createdOn="}> <i
                      class="fas fa-bullhorn"></i> <span
                      class="menu-title text-truncate" data-i18n="Chat">Campaign
                   Manager</span>
                   </a>
                </li>
                <li id="profileSettingsli" class="nav-item">
                   <a
                   href={process.env.REACT_APP_PORTAL_URl+"/WhatsappManager/profilesettings"}
                   class="d-flex align-items-center chatManagerAnc"> <i
                      class="fab fa-whatsapp ml-25"></i><span class="nav-header-primary">WhatsApp Profile<span class="pull-right"><b class="caret"></b></span>
                   </span>
                   </a>
                </li>
                <li class="contactsLi nav-item">
                   <a
                   class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/contacts/index?name=&number=&email=&status=&customAttributes=&pageSize="}><i class="fas fa-users"></i><span class="menu-title text-truncate"
                      data-i18n="Contacts">Contacts</span></a>
                </li>

                <div className='nav-separator'></div>

                <li id="chatobotBuilderLi" className={`nav-item ${(location.pathname === "/" || location.pathname.includes("/settings") )? 'active' : ''}`}>
                   <a id="chatAnchor"
                   class="d-flex align-items-center"
                   href={`${STRINGS.ROUTES.ROOT}?org=${localStorage.getItem('userId')}`}> <i
                      class="fas fa-network-wired"></i> 
                   <span class="menu-title text-truncate" 
                      data-i18n="Chat">Bot Builder</span>&nbsp;  
                   </a>
                </li>
{/* 
                <li id="channelsLi" className={`nav-item ${location.pathname === "/complain" ? 'active' : ''}`}>
                   <a class="d-flex align-items-center"
                   href={`${STRINGS.ROUTES.COMPLAIN}`}><i class="fa fa-exclamation" style={{"text-align": "center"}}></i><span
                      class="menu-title text-truncate" data-i18n="complain">Complaint</span></a>
                </li> */}

                <li id="channelsLi" class=" nav-item">
                   <a class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/channels/index"}>
                     <i class="fa fa-share-alt"></i>
                     <span  class="menu-title text-truncate" data-i18n="channels">Channels</span></a>
                </li>
            
                <li id="integrationsLi" class="nav-item">
                   <a class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/integrations/index"}>
                    <i class="fas fa-cogs"></i>
                    <span class="menu-title text-truncate" data-i18n="integrations">Integrations</span>
                    </a>
                </li>
             
               
                <li id="usermanagementLi" class="nav-item">
                   <a class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/usermanagement/summary"}>
                   {/* <i className='fa fa-shield'></i> */}
                     <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg>
                     <span class="menu-title text-truncate" data-i18n="Calendar">Users</span>
                   </a>
               </li>  

                  
              <li id="teammanagementLi" class=" nav-item"><a class="d-flex align-items-center"
              href={process.env.REACT_APP_PORTAL_URl+"/teammanagement/summary?teamName=&status="}>
               <i class="fas fa-user-friends"></i>               
               <span
                class="menu-title text-truncate" data-i18n="Todo">Teams</span></a></li>

               <div className='nav-separator'></div>

                <li id="billingLi" class="position_abs position_abs_b1 w-100 nav-item"
                   >
                   <a class="d-flex align-items-center"
                   href={process.env.REACT_APP_PORTAL_URl+"/billing/index"}>
                   <i class="fas fa-hand-holding-usd"></i> <span
                      class="menu-title text-truncate" data-i18n="Kanban">Billing</span>
                   </a>
                </li>
             <li id="accountSetLi" class="position_abs position_abs_b2 w-100 nav-item"
                >
                <a class="d-flex align-items-center"
                href={process.env.REACT_APP_PORTAL_URl+"/accountsettings/index"}>
                <i class="fas fa-cog"></i> <span
                   class="menu-title text-truncate" data-i18n="Kanban">Settings</span> 
                </a>
             </li>
          
              <li id="whatsNewLi" class="position_abs position_abs_b3 w-100 nav-item">
                 <a class="d-flex align-items-center"
                 id="example" data-title="Here's What's New!!"
                 data-toggle="clickover" data-placement="right"
                 href={process.env.REACT_APP_PORTAL_URl+"/whatsnew/index"}>
                 <i class="far fa-star"></i> <span class="menu-title text-truncate"
                    data-i18n="Kanban">What's New</span>  &nbsp;
                     <span
                    class="new-badge badge badge-pill badge-primary float-right position-relative"
                    >New</span>
                 </a>
              </li>
          </ul>
       </div>
      </div>
      </div>
   );
};

export default NavBar;
