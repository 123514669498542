import React from "react";



const MedicalList = ({pageName,dataName}) => {
 


 
 


  



  return (
    <>
   <section className="content">
      <div className="row">       
        <div className="col-12">
          <div >
          <div className="box-body">

              <div className="row">
                    <div className="col-12">
                      <h4 className="box-title">List</h4>
                    </div>
                    
                </div>
                </div>
                </div>
                </div>
                </div>
    </section>



    </>
  );
};

export default MedicalList;
