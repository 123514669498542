import React, { useState, useEffect } from "react";
import List from "./customer/List";
import Create from "./customer/Create";
import ClinicOwner from "./customer/ClinicOwner";
import Detail from "./customer/Detail";
import { useParams } from 'react-router';
import { useHistory} from 'react-router-dom';

const Bot = () => {





  return (
    <>
      
     <div>Bot</div>
      
   
    </>
  );
};

export default Bot;
