import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalPopup = ({ show, close, title, children, onHide, visible }) => {


  return (
    <>
     {
     show ?
     
     <Modal 
      onHide={onHide}
      show={visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content modalBorderRadius">      
        <div className="modal-header bg-white modalBorderRadius" style={{padding: "0.5rem 1rem"}}>
            <h4 className="modal-title ">{title}</h4>
            <button type="button" className="close createuser-close text-dark mr-0 pt-25 shadow-none font-weight-light" onClick={close}>&times;</button>
        </div>

        <div className="modal-body">
          <div style={{minWidth:'500px'}}  >
            {children}
          </div>
        </div>
      </div>
    </Modal>
      : null
     }
  
    </>
  );
};

export default ModalPopup;